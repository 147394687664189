
import request from "../utils/request";
/**
 * 订单列表
 * @param query
 * @returns {AxiosPromise}
 */
export const list = (query) => {
    return request({
        url: '/sys/orders/list',
        method: 'get',
        params: query
    });
};
export const page = (query) => {
    return request({
        url: '/sys/orders/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/***
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export function exportExcel(query) {
    return request({
        url: '/sys/orders/excel',
        method: 'post',
        data: query,
    })
}
/***
 * 导出订单修改记录
 * @param query
 * @returns {AxiosPromise}
 */
export function exportUpdateExcel(query) {
    return request({
        url: '/sys/orders/details/exportUpdateRecord',
        method: 'post',
        data: query,
    })
}

/**
 * 合同归档
 */
export const filing = (id) => {
    return request({
        url: '/sys/orders/filing',
        method: 'get',
        params: { id }
    });
};
/**
 * 驳回合同
 */
export const rejected = (id) => {
    return request({
        url: '/sys/orders/rejected',
        method: 'get',
        params: {
            id
        }
    });
};

/**
 * 删除订单
 * @param oid
 * @returns {AxiosPromise}
 */
export const del = (oid) => {
    return request({
        url: '/sys/orders/del',
        method: 'get',
        params: { oid }
    });
};

/**
 * 导入订单
 * @param query
 * @returns {AxiosPromise}
 */
export const uploadSkus = (file) => {
    return request({
        url: '/sys/orders/upload',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};

// 导入免费课程
export const uploadFreeLesson = (file) => {
    return request({
        url: '/sys/orders/addFreeCourse',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};




/**
 * 导入商户单号核实业绩
 * @param query
 * @returns {AxiosPromise}
 */
export const verification = (file) => {
    return request({
        url: '/sys/orders/verification',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        responseType: 'arraybuffer',
        data: file
    });
};
/**
 * 重新 生成二维码
 * @param oid
 * @returns {AxiosPromise}
 */
export const reQrCode = (oid) => {
    return request({
        url: '/sys/orders/reQrCode',
        method: 'get',
        params: {
            oid
        }
    });
};
/**
 * 发送短信提醒
 */
export const send = (form) => {
    return request({
        url: '/sys/orders/send',
        method: 'post',
        data: form
    });
};
export const listSource = () => {
    return request({
        url: '/sys/source/listSource',
        method: 'get',
        params: {}
    });
};

/**
 * 退费短信通知
 * @param query
 * @returns {AxiosPromise}
 */
export const financeSend = (file) => {
    return request({
        url: '/sys/orders/financeSend',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        responseType: 'arraybuffer',
        data: file
    });
};
/**
 * 查看order的转卡信息 根据旧卡id 和新卡id
 * @param oldId 旧卡Id
 * @param newId 新卡Id
 * @return
 */
export function selectOrderTurnInfoById(oldId, newId) {
    return request({
        url: '/sys/orders/selectOrderTurnInfoById',
        method: 'get',
        params: { oldId, newId }
    })
}

/**
 * 批量延期
 */
export const selectPostpone = (list) => {
    return request({
        url: '/sys/orders/selectPostpone',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/**
 * 学员转移
 */
export const transferTeacher = (obj) => {
    return request({
        url: '/sys/orders/updateBatchTeacherId',
        method: 'GET',
        params: {
            newCampusId: obj.transferTargetId,
            oldCampusId: obj.transferObjectId,
            searchType: obj.searchType,
        }
    });
};
/**
 * 查看停卡中的学员
 */
export const getStopOrder = (param) => {
    return request({
        url: '/sys/order/deactivate/pageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/**
 * 修改审核状态
 */
export const updateState = (id, state, time) => {
    return request({
        url: '/sys/order/deactivate/updateState',
        method: 'get',
        params: { id, state, time }
    });
};


/**
 *   审核停卡申请
 */
export const updateStopOrderState = (param) => {
    return request({
        url: '/sys/order/deactivate/updateState',
        method: 'get',
        params: param
    });
};

/***
 * 导出订单分摊 数据
 * @param query
 * @returns {AxiosPromise}
 */
export function exportExcelApportion(query) {
    return request({
        url: '/sys/orders/apportion/financialOrderSQPageExcel',
        method: 'POST',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

/**
 *   查询订单分摊后的数据
 */
export const getOrderApportionData = (param) => {
    return request({
        url: '/sys/orders/apportion/financialOrderSQPage',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 *   查询地市订单分摊后的数据
 */
export const getCitiesApportionData = (param) => {
    return request({
        url: '/sys/orders/apportion/apportion',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
// 查询贝体订单分摊后的数据
export const getbodyFinancial = (param) => {
    return request({
        url: '/sys/orders/apportion/bodyFinancial',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};



/***
 * 导出地市订单分摊后的数据
 * @param query
 * @returns {AxiosPromise}
 */
export function excelCitiesApportionDataExcel(query) {
    return request({
        url: '/sys/orders/apportion/apportionExcel',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}



/**
 *   根据上课记录查询订单后分摊后的数据
 */
export const getFinancialApportionOrderData = (param) => {
    return request({
        url: '/sys/orders/apportion/financialOrderPage',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/***
 * 根据上课记录导出订单分摊 数据
 * @param query
 * @returns {AxiosPromise}
 */
export function exportExcelFinancialApportion(query) {
    return request({
        url: '/sys/orders/apportion/financialOrderExcel',
        method: 'POST',
        responseType: 'arraybuffer',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

/**
 * 导入订单
 * @param query
 * @returns {AxiosPromise}
 */
export const uploadOrderState = (file) => {
    return request({
        url: '/sys/orders/uploadOrderState',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};
/**
 *订单主卡详情上课记录
 * @param orderId
 * @returns {AxiosPromise}
 */
export const selectOrderCourses = (orderId) => {
    return request({
        url: '/sys/orders/details/selectOrderCourses',
        method: 'get',
        params: { orderId }
    });
};


/**
 * 修改学员信息
 */
export const updateOrderInfo = (list) => {
    return request({
        url: '/sys/orders/updateOrderInfo',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/**
 * 修改学员主卡
 */
export const updateOrder = (list) => {
    return request({
        url: '/sys/orders/updateOrder',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/**
 * 修改学员赠卡
 * @param file
 * @returns {AxiosPromise}
 */
export const updateSendOrder = (form) => {
    return request({
        url: '/sys/orders/updateSendOrder',
        method: 'post',
        data: form
    });
};

//导出excell贝体数据
export function exportbodyFinancialExcel(query) {
    return request({
        url: '/sys/orders/apportion/bodyFinancialExcel',///api/sys/orders/apportion/bodyFinancialExcel
        method: 'POST',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

//点击调用websocket
export const webSocketExcell = (param) => {
    return request({
        url: '/sys/down/excel/list',
        method: 'get',
        params: param
    });
};

//工具栏导出数据
export function toolOrdersExcel(date) {
    return request({
        url: '/sys/tools/excel',
        method: 'GET',
        params: {
            date
        }
    })
}
//获取已记录课时
export function getorderLesson(orderId, type) {
    return request({
        url: '/sys/orders/details/sumOrderCourses',
        method: 'get',
        params: { orderId, type }
    })
}

// 查询订单修改记录
export function getorderEdit(orderId) {
    return request({
        url: '/sys/orders/details/selectUpdateRecord',
        method: 'get',
        params: { orderId }
    })
}
//确定延期
export const updatePostpone = (query) => {
    return request({
        url: '/sys/orders/updatePostpone',
        method: 'POST',
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
//导入表格
export const uploadExcell = (file) => {
    return request({
        url: '/sys/orders/importIdDerivePhone',
        method: 'POST',
        data: file,
        headers: {
            "Content-type": "multipart/form-data",
        },
        responseType: 'arraybuffer',
    });
};


//获取验证码
export const getCode = (note,number) => {
    return request({
        url: '/sys/orders/exportPhoneVerify',
        method: 'get',
        params: {
            note,
            number
        }
      
    });
};





/**
 * 退费导入
 * @param query
 * @returns {AxiosPromise}
 */
export const importRefund = (file) => {
    return request({
        url: '/sys/coachPerformanceTarget/importRefund',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};