import {ElNotification} from "element-plus";

let webSock = null
let messageCallback = null
// let errorCallback = null
let userName = localStorage.getItem('ms_username');//当前用户
let token = localStorage.getItem('token');
let url="wss://minprogram.yanhuotiyu.com/wss/api/webSocket/"
// let url="ws://192.168.10.181:8888/api/webSocket/"
// let url="ws://192.168.10.22:9099/api/webSocket/"
/**
 * 发起websocket连接
 * @param {Object} agentData 需要向后台传递的参数数据
 */
function websocketSend (agentData) {
    // 加延迟是为了尽量让ws连接状态变为OPEN
    setTimeout(() => {
        // 添加状态判断，当为OPEN时，发送消息
        if (webSock.readyState === webSock.OPEN) { // websock.OPEN = 1
            // 发给后端的数据需要字符串化
            webSock.send(JSON.stringify(agentData))
        }
        if (webSock.readyState === webSock.CLOSED) { // websock.CLOSED = 3
            console.log('ws连接异常，请稍候重试')
        }
    }, 500)
}
// 接收ws后端返回的数据
function webSocketMessage (e) {
    let val = JSON.parse(e.data);
    ElNotification({
        title: val.title,
        message: val.message,
        type: val.type,
        duration:3000,
    })
    messageCallback(val)
}
// 关闭ws连接
function webSocketClose (e) {
    // e.code !== 1000  表示非正常关闭。
    if (e && e.code !== 1000) {
        console.log('wss：连接异常，请稍候重试')
        // errorCallback()
    }
}
// 建立ws连接
function webSocketOpen () {
    console.log("wss：连接成功！")
}


// 初始化weoSocket
function initWebSocket () {
    if (typeof (WebSocket) === 'undefined') {
        console.log('您的浏览器不支持WebSocket！')
        return false;
    }
    // ws请求完整地址
    const requestUrl = url + userName ;
    console.log(requestUrl)
    webSock = new WebSocket(requestUrl,[token])
    webSock.onmessage = function (e) {
        webSocketMessage(e)
    }
    webSock.onopen = function () {
        webSocketOpen()
    }
    webSock.onerror = function () {
        console.log('ws连接异常，请稍候重试')
        // errorCallback()
    }
    webSock.onclose = function (e) {
        console.log('wss：断开连接！'+dateFormat())
        console.log(e)
        console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
        webSocketClose()
    }
}

/**
 * 发起websocket请求函数
 * @param {string} url ws连接地址
 * @param {Object} agentData 传给后台的参数
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 * @param {function} errCallback ws连接错误的回调函数
 */
export function sendWebSocket (successCallback) {
    initWebSocket()
    messageCallback = successCallback;
    websocketSend('');
}

/**
 * 关闭websocket函数
 */
export function closeWebsocket () {
    if (webSock) {
        webSock.close() // 关闭websocket
        webSock.onclose() // 关闭websocket
    }
}
/**
 * 心跳检测
 */
export function heartCheck () {
    webSock.send("ping");
    console.log("发送：ping", "时间："+dateFormat());
}

function dateFormat(){
    var date=new Date();
    var year=date.getFullYear();
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
    var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
    var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
    var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
    var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
    // 拼接
    return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
}