/**
 * 递归找出要跳转到的页面对象
 * @param list 全部的页面路径
 * @param toPage 要跳转到的页面路径
 */
export function getTypeList(list, toPage) {
    list.forEach(items => {
        if (items != null) {
            let thisPage = "/" + items.index;
            //console.info("当前页面" + items.index)
            //console.info("要去的页面" + toPage)
            //console.info("对比结果:" + thisPage === toPage)

            if (thisPage === toPage) {
                localStorage.setItem("thisPage", JSON.stringify(items));
                return;
            }
            if (items.children != null && items.children.length > 0 && items.children[0].isButton === 0) {
                getTypeList(items.children, toPage);
            }
        }
    });
}

/**
 * 递归处理list中children存在的是其他的类型全改为null
 * @param list
 */
export function removeInvalidData(list) {
    if (list == null) return list;
    list.forEach((item) => {
        if (item != null) {
            if (item.children != null && item.children.length > 0) {
                removeInvalidData(item.children);
            } else {
                item.children = null;
            }
        }
    });
    return list;
}

/**
 * 判断mainList是否包含subList
 * @param mainList
 * @param subList
 * @returns {boolean}
 */
export function include(mainList, subList) {

    const mainSet = new Set(mainList);
    for (const item of subList) {
        if (!mainSet.has(item)) {
            return false;
        }
    }
    return true;
}