<template>
  <div class="header">
    <div></div>
    <div class="logo">
    </div>
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
<!--      <i v-if="!collapse" icon="Search"></i>-->
<!--      <i v-else icon="Search"></i>-->
      <el-button style="color:#f0f0f0;font-size: 22px" type="primary" icon="Operation" link circle />
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 下载分摊消息 -->
        <el-badge :is-dot="!messageExcellReadS" type="danger">
          <el-popover placement="bottom-start" :width="340" trigger="click">
            <div style="height: 500px; overflow: auto; padding-right: 10px">
              <div
                v-for="(item, index) in messageExcells"
                :v-if="messageExcells.length > 0"
                :key="index"
              >
                <div
                  @mouseleave="bgcolor = null"
                  :class="{ cur: bgcolor === index }"
                  class="card"
                >
                  <div class="title">
                    <h4>{{ item.name }}</h4>
                  </div>
                  <div style="display: flex; align-items: center;position: relative;">
                    <div>
                      <div style="margin: 10px 0 0 0">{{ item.updateTime }}</div>
                      <div>
                        <el-button  v-if="item.state==1 " disabled  type="primary" size="small" class="download">下载中</el-button>
                        <el-button  v-if="item.state==3 " disabled  type="danger" size="small" class="download">失败</el-button>
                        <el-button  v-if="item.state==2 " type="primary" size="small" @click="Download(item.url)" class="download">下载</el-button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div

                v-if="messageExcells.length <= 0"
                style="
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div style="text-align: center">
                  <i
                    style="font-size: 66px"
                    class="iconfont icon-queshengye_zanwuxiaoxi"
                  ></i>
                  <div>您还没有消息呦 ~</div>
                </div>
              </div>
            </div>
            <template #reference>
              <el-icon style="font-size: 20px; margin: 0 5px" @click="yesMessageEcellRead()">
                <Download />
              </el-icon>
            </template>
          </el-popover>
        </el-badge>
        <!-- 用户工具 -->
        <el-badge type="danger">
          <el-popover
            ref="mergePopover"
            placement="bottom-start"
            :width="350"
            trigger="click"
          >
            <div style="height: 350px; overflow: auto; padding-right: 10px">
              <div v-if="false" style="text-align: center; margin-top: 50px">
                <i
                  style="font-size: 66px"
                  class="iconfont icon-queshengye_zanwugongzuo"
                ></i>
                <div>您还没有可用功能呦 ~</div>
              </div>
              <div v-else>
                <instrument @closePopover="closePopover" />
              </div>
            </div>
            <template #reference>
              <el-icon style="font-size: 20px; margin: 0 5px">
                <Menu />
              </el-icon>
            </template>
          </el-popover>
        </el-badge>
        <!-- 用户消息 -->
        <el-badge :is-dot="!messageRead" type="danger">
          <el-popover placement="bottom-start" :width="340" trigger="click">
            <div style="height: 500px; overflow: auto; padding-right: 10px">
              <div
                v-for="(item, index) in message"
                :v-if="message.length > 0"
                :key="index"
              >
                <div
                  @mouseenter="changebgColor(index)"
                  @mouseleave="bgcolor = null"
                  :class="{ cur: bgcolor === index }"
                  class="card"
                >
                  <div class="title">
                    <h4>{{ item.title }}</h4>
                    <el-button
                      @click="deleteMessage(index)"
                      style="margin-top: -10px; color: #969896"
                      type="text"
                      >×</el-button
                    >
                    <!--<span style="font-size: 18px"></span>-->
                  </div>
                  <div style="display: flex; align-items: center">
                    <div>
                      <el-button
                        v-if="item.type == 'warning'"
                        type="warning"
                        size="small"
                        circle
                      />
                      <el-button
                        v-if="item.type == 'success'"
                        type="success"
                        size="small"
                        class="el-icon-check"
                        circle
                      />
                      <el-button
                        v-if="item.type == 'error'"
                        type="danger"
                        size="small"
                        circle
                      />
                    </div>
                    <div
                      style="
                        border: 1px #ebeef5 solid;
                        height: 30px;
                        margin: 0 12px 0 10px;
                      "
                    ></div>
                    <div>
                      <div>{{ item.date }}</div>
                      <div style="margin: 3px 0"></div>
                      <div>{{ item.message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="message.length <= 0"
                style="
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div style="text-align: center">
                  <i
                    style="font-size: 66px"
                    class="iconfont icon-queshengye_zanwuxiaoxi"
                  ></i>
                  <div>您还没有消息呦 ~</div>
                </div>
              </div>
            </div>
            <template #reference>
              <el-icon style="font-size: 20px; margin: 0 5px" @click="yesMessageRead()">
                <ChatDotRound />
              </el-icon>
            </template>
          </el-popover>
        </el-badge>
        <!-- 用户头像 -->
        <div class="user-avator demo-type">
          <el-avatar
            style="height: 30px; width: 30px"
            src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80"
          />
        </div>
        <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link" >
          {{ username }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="rePwd">修改密码</el-dropdown-item>
              <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- 用户名下拉菜单 -->
<!--        <el-dropdown class="user-name" trigger="click" @command="handleCommand">-->
<!--          {{ username }}-->
<!--          <el-button style="color:#f0f0f0;" type="primary" icon="ArrowDown" link circle />-->

<!--          <span class="el-dropdown-link">-->
<!--            {{ username }}-->
<!--            <i class="el-icon-caret-bottom"></i>-->
<!--          </span>-->
<!--          <template #dropdown>-->
<!--            <el-dropdown-menu>-->
<!--              &lt;!&ndash;              <el-dropdown-item divided command="reImg">修改头像</el-dropdown-item>&ndash;&gt;-->
<!--              <el-dropdown-item divided command="rePwd"-->
<!--                >修改密码</el-dropdown-item-->
<!--              >-->
<!--              <el-dropdown-item divided command="loginout"-->
<!--                >退出登录</el-dropdown-item-->
<!--              >-->
<!--            </el-dropdown-menu>-->
<!--          </template>-->
<!--        </el-dropdown>-->
      </div>
    </div>
  </div>

  <!--修改密码-->
  <el-dialog title="用户修改密码" v-model="editPwd" width="30%">
    <el-form :rules="rules" ref="ruleForm" :model="addForm" label-width="90px">
      <el-form-item prop="password" label="旧密码">
        <el-input v-model="addForm.password"></el-input>
      </el-form-item>

      <el-form-item prop="newPassword" label="新密码">
        <el-input v-model="addForm.newPassword"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editPwd = false">取 消</el-button>
        <el-button type="primary" @click="rePassword('ruleForm')"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!--修改头像-->
  <el-dialog title="用户修改头像" v-model="editImg" width="30%">
    <el-upload
      class="avatar-uploader"
      action="/api/sys/reImg"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
    >
      <img v-if="newImageUrl" :src="newImageUrl" class="avatar" />
      <i v-else class="Plus avatar-uploader-icon"></i>
    </el-upload>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editImg = false">取 消</el-button>
        <el-button type="primary" @click="rePassword('ruleForm')"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { rePwd } from "@/api/login";
import instrument from "./tools/Instrument";
import { webSocketExcell } from "@/api/orders";
import bus from '@/utils/bus.js'
export default {
  components: {
    instrument,
  },
  data() {
    return {
      type:'',
      newImageUrl: "",
      editPwd: false,
      editImg: false,
      addForm: {
        password:"",
        newPassword:""
      },
      rules: {
        password: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
      message: [], 
      messageRead: true,
      messageExcells: [],
      messageExcellReadS: true,
      bgcolor: null,
    };
  },
  computed: {
    imgUrl() {
      return localStorage.getItem("imgUrl");
    },
    username() {
      let username = localStorage.getItem("ms_username");
      return username;
    },
    collapse() {
      return this.$store.state.collapse;
    },
    websocket() {
      return this.$store.state.webSocket.message; //这里返回vuex的state里的str数据
    },
    messageReads() {
      return this.$store.state.webSocket.messageRead;
    },
    messageExcellRead() {
      return this.$store.state.webSocket.messageExcellReadS;
    },
  },
  methods: {
    Download(url) {
      window.URL = window.URL || window.webkitURL;
      var name = url.substring(url.lastIndexOf("/") + 1, url.length);
      var xhr = new XMLHttpRequest();
      var a = document.createElement("a");
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function() {
        var file = new Blob([xhr.response], { type: "application/octet-stream" });
        a.href = window.URL.createObjectURL(file);
        a.download = name;
        a.click();
      };
      xhr.send();
    },
    //移除消息
    deleteMessage(index) {
      let messageList = this.message;
      messageList.splice(index, 1);
      this.$store.commit("setWebSocket", messageList);
    },
    // 修改密码
    rePassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.editPwd = false;
          let username = localStorage.getItem("ms_username");
          this.addForm.username = username;
          rePwd(this.addForm).then((res) => {
            if (res.code == 200) {
              this.$message.success("修改成功，请重新登录！");
              localStorage.removeItem("ms_username");
              this.$router.push("/login");
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.newImageUrl = URL.createObjectURL(file.raw);
    },
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      console.log(command)
      if (command == "loginout") {
        localStorage.removeItem("ms_username");
        localStorage.removeItem('token')
        this.$router.push("/login");
      } else if (command == "rePwd") {
        //修改密码
        this.editPwd = true;
      } else if (command == "reImg") {
        //修改头像
        this.editImg = true;
      }
    },
    // 侧边栏折叠
    collapseChage() {
      this.$store.commit("hadndleCollapse", !this.collapse);
    },
    //当前鼠标选中消息
    changebgColor(index) {
      this.bgcolor = index;
    },
    yesMessageRead() {
      this.$store.commit("setMessageRead", true);
    },
    yesMessageEcellRead() {
      this.$store.commit("setMessageReadExcell", true);
      webSocketExcell().then((res) => {
        if(res.code==200){ 
           this.messageExcells = res.data;
        }else {
          this.$message.error('下载失败，请重新下载');
        }
       
      });
    },
    closePopover() {
      this.$refs.mergePopover.hide();
      this.instrument.dialogPostpone = true;
    },
  },
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
    bus.on('sendMsg',(value)=>{ 
      this.type = value
      if(this.type!=''){ 
        this.yesMessageEcellRead()
      }
     
    })
  },
  watch: {
    // type:{ 
    //   handler:function(newVal){ 
    //     console.log(newVal);
    //   },
    //   deep: true, // 深度监听
    //   immediate: true, //立即执行
    // },  
    websocket: {
      handler: function (newVal) {
        this.message = new Array(...newVal).reverse();
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
    messageReads: {
      handler: function (newVal) {
        this.messageRead = newVal;
      },
    },
    messageExcellRead: {
      handler: function (newVal) {
        this.messageExcellReadS = newVal;
        console.log(this.messageExcellReadS);
      },
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 7px;
  height: 1px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c7c9ce;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ebeef5;
  background: #ededed;
  border-radius: 5px;
}
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: #fff;
}

.collapse-btn {
  float: left;
  padding: 0 21px;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
}

.header .logo {
  text-align: center;
  float: left;
  background-color: white;
}

.header-right {
  float: right;
  padding-right: 50px;
}

.header-user-con {
  display: flex;
  height: 50px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.btn-bell .el-icon-bell {
  color: #fff;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-right: 10px;
  margin-left: 25px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}
.el-checkbox__inner {
  border-radius: 56px;
}
.card {
  border: #ebeef5 solid 1px;
  padding: 10px 10px;
  margin: 10px 0;
  border-radius: 12px;
}
.title {
  display: flex;
  justify-content: space-between;
  padding: 0 6px 0 0px;
}
.cur {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.download{
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
