import {createApp} from 'vue';
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue';
import router from './router';
import store from './store/index';
import installElementPlus from './plugins/element';

import './assets/css/icon.css';
import './assets/login/css/main.css';
import directives from './utils/directive';
import {include} from './utils/methodUtil';
import dataV from '@jiaminghi/data-view'


const app = createApp(App)
for (let i in ElementPlusIconsVue) {
    app.component(i, ElementPlusIconsVue[i])
}
installElementPlus(app)
directives(app)

//全局自定义指令 v-permission=['页面子级按钮权限的index']
// vue自定义指令传入的值可以是xxPage:xxAbc 只要和 sys_user_menu某页面下的is_button类型=1的按钮的权限的index 相同即可
app.directive('permission', {
    mounted(el, binding) {
        //当前也按钮所需要的权限
        const permissionValue = binding.value;
        // console.log('aaaaaaaaaaaa')
        // console.log("存在权限判定:" + permissionValue)
        //当前页面用户所拥有的权限
        let thisPage = JSON.parse(localStorage.getItem('thisPage'));
        if (permissionValue != null && permissionValue.length > 0) {
            if (thisPage != null && thisPage.permission != null && thisPage.permission.length > 0) {
                if (include(thisPage.permission, permissionValue) === true) {
                    return;
                }
            }
        }
        el.style.display = 'none'; // 如果用户没有权限，隐藏按钮
        el.disabled = true; // 禁用按钮
    }
}).use(store).use(router).use(ElementPlus).use(dataV).mount('#app')


// 防止localStorage被修改
window.addEventListener('storage', function (e) {
    localStorage.setItem(e.key, e.oldValue)
});




