import request from '../utils/request';

/**
 * 添加校区
 * @param param
 * @returns {*}
 */
export const addCampus = (param) => {
    return request({
        url: '/sys/area/addCampus',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 添加校区
 * @param param
 * @returns {*}
 */
export const editCampus = (param) => {
    return request({
        url: '/sys/area/updateCampus',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
export const tree = (type) => {
    return request({
        url: '/sys/area/listTree',
        method: 'GET',
        params: {type}
    });
};

//添加超级管理员用户查询可视区域
export const listAreaTreeAddUser = (type) => {
    return request({
        url: '/sys/area/listAreaTreeAddUser',
        method: 'GET',
        params: {type}
    });
};
//全局区域查询筛选框

export const listCampusTree = (type) => {
    return request({
        url: '/sys/area/listCampusTree',
        method: 'GET',
        params:{type}
    });
};

//添加教练查询可视区域
export const listAreaTreeAddCoach = (type) => {
    return request({
        url: '/sys/area/listAreaTreeAddCoach',
        method: 'GET',
        params:{type}
    });
};


//点击返回校区
export const selectCampusByAreaId = (query) => {
    return request({
        url: '/sys/area/listClickCampus',
        method: 'post',
        data: query
    });
};
//修改状态
export const updateCampusState = (areaId, state) => {
    return request({
        url: '/sys/area/updateCampusState',
        method: 'GET',
        params: {areaId, state}
    });
};


//根据分部-查询区域
export const selectAreaByExpress = (type) => {
    return request({
        url: '/sys/area/selectAreaByExpress',
        method: 'GET',
        data:{type}
    });
};


export const addArea = (form)=>{
    return request({
        url: '/sys/area/addArea',
        method: 'post',
        data:form
    });
}
//导出
export const exportArea = (keyword,pageIndex,pageSize,regionId)=>{
    return request({
        url: '/sys/campus/excel',
        responseType: 'arraybuffer',
        method: 'GET',
        params:{
            keyword,pageIndex,pageSize,regionId
        }
    });
}


export const exportCampus = (query) => {
    return request({
        url: '/sys/area/exportArea',
        method: 'post',
        data: query
    });
};
