<template>
  <div></div>
</template>

<script>
import {pushApportion, pushOrder, pushCourse, pushAdvance, pushEquip, pushZhaoShangCRMOrder} from "@/api/tools";
import {ElNotification} from "element-plus";

export default {
  name: "Tools",
  data() {
    return {}
  },
  methods: {
    message(title, info, type) {
      ElNotification({
        title: title,
        message: info,
        type: type,
      })
    },

    pushApportion() {
      this.$message.success('系统正在处理中。。。')
      pushApportion().then(val => {
        if (val.code == 200) {
          this.message("已发送", "系统推送完成！", "success")
        } else {
          this.message("推送失败", val.message, "error")
        }
      });
    },
    pushOrder() {
      this.$message.success('系统正在处理中。。。')
      pushOrder().then(val => {
        if (val.code == 200) {
          this.message("已发送", "系统推送完成！", "success")
        } else {
          this.message("推送失败", val.message, "error")
        }
      });
    },
    pushCourse() {
      this.$message.success('系统正在处理中。。。')
      pushCourse().then(val => {
        if (val.code == 200) {
          this.message("已发送", "系统推送完成！", "success")
        } else {
          this.message("推送失败", val.message, "error")
        }
      });
    },
    pushAdvance() {
      this.$message.success('系统正在处理中。。。')
      pushAdvance().then(val => {
        if (val.code == 200) {
          this.message("已发送", "系统推送完成！", "success")
        } else {
          this.message("推送失败", val.message, "error")
        }
      });
    },

    pushEquip() {
      this.$message.success('系统正在处理中。。。')
      pushEquip().then(val => {
        if (val.code == 200) {
          this.message("已发送", "系统推送完成！", "success")
        } else {
          this.message("推送失败", val.message, "error")
        }
      });
    },

    pushZhaoShangCRMOrder() {
      this.$message.success('系统正在处理中。。。')
      pushZhaoShangCRMOrder().then(val => {
        if (val.code == 200) {
          this.message("已发送", "系统推送完成！", "success")
        } else {
          this.message("推送失败", val.message, "error")
        }
      });
    },
  }
}
</script>

<style scoped>

</style>