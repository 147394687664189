<template>
  <div class="sidebar">
    <el-menu
        class="el-menu-vertical-demo"
        :default-active="onRoutes"
        :collapse="collapse"
        background-color="#324157"
        text-color="#bfcbd9"
        active-text-color="#20a0ff"
        unique-opened
        router>
        <ActMenu :list="menu"></ActMenu>
    </el-menu>
  </div>
</template>

<script>

import ActMenu from "./SidbarItem.vue";
export default {
  components: { ActMenu },
  data() {
    return {
      menu:JSON.parse(localStorage.getItem('menu')),
    };
  },
  created() {
    this.roleId=localStorage.getItem("roleId")
    console.log(this.menu)
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.collapse
    }
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #324157;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
}

.sidebar > ul {
  height: 100%;
}
</style>
