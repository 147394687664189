<template>
  <div>
    <!--    工具-->
    <div class="tool">
      <div v-for="item in toolsMenu" :key="item.id">
        <div class="tool-item" @click="openDialog(item.dialog)">
          <i
              style="font-size: 36px; margin: 0 5px"
              :class="'img iconfont ' + item.icon"
          ></i>
          <div class="tool-title">{{ item.name }}</div>
        </div>
      </div>
      <div class="list-empty"></div>
      <div class="list-empty"></div>
    </div>

    <!-- 批量延期弹出层 -->

    <el-dialog v-model="selectPostponeListDialog" width="1200px" title="查询延期对象" :append-to-body="true"
               :draggable="true">
      <template #default>
        <el-dialog
            v-loading="loadingDia"
            v-model="postponeDialog"
            width="1200px"
            title="选择延期对象"
            append-to-body
        >
          <span style="margin-right: 20px">共计：{{ postponeList.length }} 位学员 </span>

          <el-tooltip
              :content="checkAllMain!=true ? '全选主卡':'取消主卡全选'"
              placement="top">
            <el-switch v-model="checkAllMain" class="mt-2" inline-prompt/>
          </el-tooltip>
          <el-tooltip
              :content="checkAllVice!=true ? '全选副卡':'取消副卡全选'" style="margin-left: 20px"
              placement="top">
            <el-switch v-model="checkAllVice" class="mt-2" inline-prompt/>
          </el-tooltip>

          <div class="postponeList">
            <div v-for="(item,index) in postponeList" :key="index"
                 style="background-color: #F5F7FA;margin: 20px;padding: 20px;border-radius: 20px">
              <div>
                <span>姓名：{{ item.studentName }}</span>
                <span style="margin-left: 20px">电话：{{ item.studentPhone }}</span>
                <span style="margin-left: 20px">购买数量：{{ item.buyNumber }}</span>
                <span v-if="item.sendNumber!=null" style="margin-left: 20px">购买数量：{{ item.sendNumber }}</span>
                <span style="margin-left: 20px">提交老师：{{ item.coachName }}</span>
              </div>
              <div style="margin-top: 6px">
                <span>所属校区：{{ item.submitCampusName }}</span>
                <span style="margin-left: 20px">上课校区：{{ item.campusName }}</span>
                <span style="margin-left: 20px">创建日期：{{ item.createTime }}</span>
              </div>
              <div style="display: flex;justify-content: space-around;margin-top: 30px;">
                <div
                    style="width: 300px;background-color:#f8e3c5;color: #E6A23C;border-radius: 20px;padding:20px 20px;position: relative">
                  <div style="text-align: center;margin-bottom: 10px;font-weight: 900">主卡{{ item.orderId }}</div>
                  <el-tooltip
                      :content="item.orderId == null ? '无效延期对象，不可选择！': item.isCheck!=true ? '选择延期':'取消选择'"
                      placement="top">
                    <el-switch @change="switchChange" v-model="item.isCheck" :disabled="item.orderId == null"
                               class="mt-2" inline-prompt style="position: absolute;top: 16px;right: 18px"/>
                  </el-tooltip>

                  <div style="font-size: 12px">
                    <div style="display: flex;">
                      <div style="width: 50%">课程：{{ item.cardName }}</div>
                      <div>状态：{{ item.state }}</div>
                    </div>
                    <div style="display: flex;margin-top: 4px">
                      <div class="item">剩余数量：{{ item.remainingNum }}</div>
                      <div class="item">已用数量：{{ item.ouse }}</div>
                    </div>
                    <div style="display: flex;margin-top: 4px">
                      <div class="item">开始日期：{{ item.startTime }}</div>
                      <div class="item">结束日期：{{ item.endTime }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="item.sendorder!=null"
                     style="width: 300px;background-color:#d9ecff;border-radius: 20px;padding:20px 20px;color: #409EFF;position: relative">
                  <div style="text-align: center;margin-bottom: 10px;font-weight: 900">副卡{{
                      item.sendorder.sid
                    }}
                  </div>
                  <el-tooltip
                      :content="item.sendorder.sid == null ? '无效延期对象，不可选择！': item.isCheck!=true ? '选择延期':'取消选择'"
                      placement="top">
                    <el-switch v-model="item.sendorder.isCheck" :disabled="item.sendorder.sid == null" class="mt-2"
                               inline-prompt style="position: absolute;top: 16px;right: 18px"/>
                  </el-tooltip>
                  <div style="font-size: 12px">
                    <div style="display: flex;">
                      <div style="width: 50%">
                        课程：{{
                          item.sendorder.cardAttributeRule.name == null ? '' : item.sendorder.cardAttributeRule.name
                        }}
                      </div>
                      <div>状态：{{ item.sendorder.state }}</div>
                    </div>
                    <div style="display: flex;margin-top: 4px">
                      <div class="item">剩余数量：{{ item.sendorder.remaining }}</div>
                      <div class="item">已用数量：{{ item.sendorder.suse }}</div>
                    </div>
                    <div style="display: flex;margin-top: 4px">
                      <div class="item">开始日期：{{ item.sendorder.startTime }}</div>
                      <div class="item">结束日期：{{ item.sendorder.endTime }}</div>
                    </div>
                  </div>
                </div>
                <div v-else style="width: 340px;"></div>
              </div>
            </div>
          </div>
          <div style="margin-top: 50px;text-align: right" class="dialog-footer">
            <el-button @click="postponeDialog = false">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
          </div>
        </el-dialog>
      </template>
      <template #footer>
        <div style="text-align: left" v-loading="loadingDia">
          <el-form
              :inline="true"
              :model="postponeForm"
              ref="postponeRules"
              :rules="postponeRules"
              class="demo-form-inline"
          >
            <h3 style="margin-bottom: 8px">延期对象</h3>
            <div>
              <el-form-item prop="regionId" label="校区">
                <select-area size="small" @areaListId="getAreaListId"/>

              </el-form-item>
              <el-form-item prop="cardTypeId" label="卡种">
                <el-select
                    v-model="postponeForm.cardTypeId"
                    placeholder="卡种类型"
                    style="width: 360px;"
                    class="handle-select mr10"
                    filterable
                    multiple
                    collapse-tags
                    collapse-tags-tooltip
                >
                  <el-option
                      style="width: 360px"
                      v-for="(item, index) in cardList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主卡/副卡">
                <el-select
                    v-model="postponeForm.type"
                    placeholder="主卡/副卡"
                    style="width: 160px;"
                    class="handle-select mr10"
                >
                  <el-option label="主卡" :value="1"></el-option>
                  <el-option label="副卡" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="display: flex; align-items: center">
              <el-form-item prop="dayNum" label="延期时间">
                <el-input-number
                    v-model="postponeForm.dayNum"
                    placeholder="天数"
                    :step="1"
                    size="small"
                    style="margin: 10px"
                    step-strictly
                />
              </el-form-item>
              <el-form-item label="延期区间">
                <el-date-picker
                    size="small"
                    v-model="postponeTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form>
          <div style="color: red">
            <h4>说明：</h4>
            <h5>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
              批量延期功能，只针对结束日期不为空白的卡片。
            </h5>
            <h5>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
              如：校区、卡种均未选择，将延期该城市全部学员。
            </h5>
            <h5>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
              延期区间是只在这个时间区间报名的才会进行，不在区间的不会进行延期。
            </h5>
            <h5>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.
              延期区间是卡片添加时间（提交业绩时间）
            </h5>
          </div>
        </div>
        <div style="margin-top: 50px" class="dialog-footer">
          <el-button @click="selectPostponeListDialog = false">取消</el-button>
          <el-button type="primary" @click="confirmPostpone('postponeRules')">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 学员转移 -->
    <el-dialog
        v-model="transferDialog"
        title="学员转移"
        :append-to-body="true"
        width="1200px"
        :draggable="true"
    >
      <el-select size="small" v-model="transferForm.searchType" placeholder="学员转移" class="searchType">
        <el-option label="所属校区" :value="1"></el-option>
        <el-option label="上课校区" :value="2"></el-option>
      </el-select>
      <el-form
          :inline="true"
          :model="transferForm"
          ref="transferValidation"
          :rules="transferValidation"
          class="demo-form-inline"
      >
        <div
            style="
            display: flex;
            justify-content: space-around;
            align-items: center;
          "
        >
          <div class="card">
            <h3 style="margin-bottom: 10px">转移对象</h3>
            <div>
              <!-- 转移对象 -->
              <el-select :size="size" placeholder="学员类型" style="width: 100px;margin-right: 10px"
                         @change="changeStudentType" v-model="studentType">
                <div v-if="sysStudentType==3">
                  <el-option label="全部"></el-option>
                  <el-option label="中招体育" value="1"></el-option>
                  <el-option label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
                <div v-if="sysStudentType!=3">
                  <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                  <el-option v-else label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
              </el-select>
              <el-cascader
                  :size="size"
                  style="margin-right: 10px; margin-top: 20px; width: 380px;  "
                  placeholder="请选择区域"
                  :options="treeList"
                  collapse-tags
                  :collapse-tags-tooltip="true"
                  clearable
                  @change="changeArea"
                  :props="{value: 'id',label: 'name',children: 'children'}"/>
            </div>
          </div>
          <div style="font-size: 58px" class="iconfont icon-jiantou"></div>
          <div class="card">
            <h3 style="margin-bottom: 10px">接收目标</h3>
            <div>
              <!-- 接收目标 -->
              <el-select :size="size" placeholder="学员类型" style="width: 100px;margin-right: 10px"
                         @change="changeStudentType1" v-model="studentType1">
                <div v-if="sysStudentType==3">
                  <el-option label="全部"></el-option>
                  <el-option label="中招体育" value="1"></el-option>
                  <el-option label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
                <div v-if="sysStudentType!=3">
                  <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                  <el-option v-else label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
              </el-select>
              <el-cascader
                  :size="size"
                  style="margin-right: 10px; margin-top: 20px; width: 380px;"
                  placeholder="请选择区域"
                  :options="treeLists"
                  collapse-tags
                  :collapse-tags-tooltip="true"
                  clearable
                  @change="changeAreas"
                  :props="{value: 'id',label: 'name',children: 'children'}"/>
            </div>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="transferDialog = false">取消</el-button>
          <el-button
              type="primary"
              @click="confirmTransfer('transferValidation')"
          >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 薪资条发放 -->
    <el-dialog
        v-model="emailDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button
            :size="small"
            type="primary"
            :loading="hintMessage == '开始发送' ? false : true"
            @click="uploadFile"
        >{{ hintMessage }}
        </el-button
        >
      </div>
      <div
          :v-if="sendEmailError.length > 0"
          v-for="(item, index) in sendEmailError"
          :key="index"
      >
        第{{ item.index }}行，{{ item.name }}薪资条发送失败！
      </div>
    </el-dialog>
    <!-- 业绩核对 -->
    <el-dialog
        v-model="performanceDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button
            :size="small"
            type="primary"
            :loading="hintMessage == '开始核实' ? false : true"
            @click="performanceVerification"
        >{{ hintMessage }}
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 订单导入 -->
    <el-dialog
        v-model="orderDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button
            :size="small"
            type="primary"
            :loading="hintMessage == '开始导入' ? false : true"
            @click="uploadOrder"
        >{{ hintMessage }}
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 免费课程 -->
    <el-dialog
        v-model="freeLesson"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button
            :size="small"
            type="primary"
            :loading="hintMessage == '开始导入' ? false : true"
            @click="uploadFreeLessonButton"
        >{{ hintMessage }}
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 退费通知 -->
    <el-dialog
        v-model="returnDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button
            :size="small"
            type="primary"
            :loading="hintMessage == '开始通知' ? false : true"
            @click="returnInform"
        >{{ hintMessage }}
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 数据导出 -->
    <el-dialog
        v-model="ordersExcelToolsDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div class="demo-date-picker">
        <span class="demonstration">缴费日期：</span>
        <div class="block">
          <el-date-picker
              v-model="Time"
              type="date"
              placeholder="请选择一天"
              size="large"
          />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ordersExcelToolsDialog = false">取消</el-button>
          <el-button type="primary" @click="orderExcel()"
          >确定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 批量挪课时 -->
    <el-dialog
        v-model="batchMoveCourseDialog"
        width="1200px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <!-- 弹框中的 元素-->
        <el-form
            :inline="true"
            :model="moveCourseFrom"
            ref="moveCourseRules"
            :rules="moveCourseRules"
            class="demo-form-inline"
        >
          <h3 style="margin-bottom: 8px">挪课时必填项</h3>

          <div>
            <el-form-item label="*挪的课所在时间区间">
              <el-date-picker
                  v-model="moveCourseTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div>
            <!-- 引用必填规则名oldOrderId-->
            <el-form-item prop="oldOrderId" label="挪出卡ID">
              <el-input
                  v-model="moveCourseFrom.oldOrderId"
                  placeholder="挪出卡ID"
              />
            </el-form-item>
          </div>
          <div>
            <!-- 引用必填规则名oldCardType-->
            <el-form-item prop="oldCardType" label="课时挪出卡是">
              <el-select
                  v-model="moveCourseFrom.oldCardType"
                  placeholder="主卡/副卡"
                  style="width: 160px;"
                  class="handle-select mr10"
              >
                <el-option label="主卡/298主卡" :value="true"></el-option>
                <el-option label="副卡/298副卡" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div>
            <!-- 引用必填规则名toOrderId-->
            <el-form-item prop="toOrderId" label="挪入卡ID">
              <el-input
                  v-model="moveCourseFrom.toOrderId"
                  placeholder="挪入卡ID"
              />
            </el-form-item>
          </div>
          <div>
            <!-- 引用必填规则名toCardType-->
            <el-form-item prop="toCardType" label="课时挪入卡是">
              <el-select
                  v-model="moveCourseFrom.toCardType"
                  placeholder="主卡/副卡"
                  style="width: 160px;"
                  class="handle-select mr10"
              >
                <el-option label="主卡/298主卡" :value="true"></el-option>
                <el-option label="副卡/298副卡" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="moveCourseNum" label="挪课精准次数">
              <el-input-number
                  v-model="moveCourseFrom.moveCourseNum"
                  placeholder="课次"
                  :step="1"
                  :min="1"
                  :max="100"
                  step-strictly
              />
            </el-form-item>
          </div>

          <div>
            <el-form-item label="挪课时备注说明">
              <el-input
                  v-model="moveCourseFrom.moveRemark"
                  style="width: 240px"
                  :rows="2"
                  type="textarea"
                  placeholder="挪课时备注说明"
              />
            </el-form-item>
          </div>

        </el-form>
        <div style="color: red">
          <h4>批量挪课时功能-说明：</h4>
          <br/>
          <h5>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
            批量挪课时功能，固定课程开始时间、固定课程结束日期
          </h5>
          <br/>
          <h5>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
            批量挪课时功能，固定挪出卡订单ID,固定挪出卡是主卡还是副卡。
          </h5>
          <br/>
          <h5>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
            批量挪课时功能，固定挪入卡订单ID,固定挪入卡是主卡还是副卡。
          </h5>
          <br/>
          <h5>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.
            批量挪课时功能，需输入完全一致的挪课精准课次数,否则不执行挪动。
          </h5>

        </div>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button
            type="primary"
            :loading="hintMessage == '开始课时挪动' ? false : true"
            @click="batchMoveCourse"
        >{{ hintMessage }}
        </el-button
        >
      </div>
    </el-dialog>

    <!-- 部分订单批量转卡 导入 -->
    <el-dialog
        v-model="partOrderMoveAreaDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button
            :size="small"
            type="primary"
            :loading="hintMessage == '开始导入' ? false : true"
            @click="partOrderMoveArea"
        >{{ hintMessage }}
        </el-button
        >
      </div>
    </el-dialog>

    <!--    批量导入本月初的分摊大表-固定所有订单的往期金额到db中-->
    <el-dialog
        v-model="importOrderBalanceDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button
            :size="small"
            type="primary"
            :loading="hintMessage == '开始导入' ? false : true"
            @click="importOrderBalance"
        >{{ hintMessage }}
        </el-button
        >
      </div>
    </el-dialog>

    <!-- 根据ID导出手机号--独立组件 -->
    <export-phone ref="child"/>

    <!-- 推送每日的各种数据-独立组件-->
    <total ref="total"/>
    <!-- 导入业绩目标-独立组件-->
    <performance-target ref="performanceTarget"/>

  </div>
</template>
<script>
import {listCampusTree} from "@/api/area.js";
import {sendEmail} from "@/api/coach";
import {
  selectPostpone,
  transferTeacher,
  verification,
  uploadSkus,
  uploadFreeLesson,
  financeSend,
  toolOrdersExcel,
  updatePostpone
} from "@/api/orders";
import {transferSelectCoach} from "@/api/coach";
import {cardListByRegion} from "@/api/public";
import {selectAreaByExpress} from "@/api/area";
//引入批量挪课时
import {partOrderMoveArea, postBatchMoveCourse, importOrderBalance} from "@/api/tools";
import {ElNotification} from "element-plus";
import selectArea from "@/components/SelectArea";
import ExportPhone from "./components/ExportPhone";
import total from "./components/Tools";
import performanceTarget from "./components/PerformanceTarget"

export default {
  components: {
    ExportPhone,
    selectArea,
    total,
    performanceTarget
  },
  props: {
    size: String,
  },
  name: "Instrument",
  data() {
    return {
      freeLesson: false,
      loadingDia: false,
      Time: '',
      treeList: [],
      treeLists: [], areaId: [],
      checkAllMain: false,
      checkAllVice: false,
      sysStudentType: localStorage.getItem('studentType'),
      studentType: null,
      studentType1: null,
      toolsMenu: JSON.parse(localStorage.getItem("toolsMenu")),
      emailDialog: false,
      selectPostponeListDialog: false,
      postponeDialog: false,
      transferDialog: false,
      performanceDialog: false,
      orderDialog: false,
      returnDialog: false,
      ordersExcelToolsDialog: false,
      batchMoveCourseDialog: false,
      partOrderMoveAreaDialog: false,
      importOrderBalanceDialog: false,
      //延期功能--表单
      postponeForm: {
        dayNum: null,
        regionId: null,
        campusId: null,

        cardTypeId: [],
        startTime: null,
        endTime: null,
        areaList: [],
        type: '',
      },
      postponeTime: null,
      postponeList: [],
      postponeRules: {
        dayNum: [
          {required: true, message: "请输入延期天数！", trigger: "blur"},
        ],
      },
      //-批量挪课时 表单------------------------------
      moveCourseFrom: {
        //挪出卡的ID
        oldOrderId: null,
        oldCardType: null,
        //挪入卡的id
        toOrderId: null,
        toCardType: null,
        //挪动课时时间范围、课次数
        moveStartTime: null,
        moveEndTime: null,
        moveCourseNum: null,
        moveRemark: null
      },
      //批量挪课时 表单必填验证
      moveCourseRules: {
        //挪出卡的ID
        oldOrderId: [
          {required: true, message: "请输入挪出卡ID！", trigger: "blur"},
        ],
        oldCardType: [
          {required: true, message: "请选择挪出卡类型！", trigger: "blur"},
        ],
        //挪入卡的id
        toOrderId: [
          {required: true, message: "请输入挪入卡ID！", trigger: "blur"},
        ],
        toCardType: [
          {required: true, message: "请选择挪入卡类型！", trigger: "blur"},
        ],
        //挪动课时时间范围、课次数
        moveCourseNum: [
          {required: true, message: "请输入精准挪的课次数！", trigger: "blur"},
        ],
      },
      //独立的时间范围选择控件绑定的字段
      moveCourseTime: null,


      dialogStudentsHandover: false,
      regionList: [],
      campusList: [],
      cardList: [],

      //学员转移
      transferRegionList: [],
      transferObjectCoachId: null,
      transferTargetCoachId: null,
      transferObjectCoachList: null,
      transferTargetCoachList: null,
      transferForm: {
        searchType: 1,
        transferObjectId: null,
        transferTargetId: null,
      },
      transferValidation: {},
      //薪资条发放
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      fileList: [],//文件list
      hintMessage: "",
      //发送薪资条邮件的异常行
      sendEmailError: [],
      //批量挪动某些订单ID到指定校区的异常行
      partOrderMoveAreaError: [],
      //导入固话的分摊余额的错误行
      importOrderBalanceError: [],
      // 延期选中的 学员
      selectList: [],
      // 延期参数
      query: {
        dayNum: '',
        mainIdList: [],
        sendIdList: []
      }
    };
  },
  created() {
    this.getAreaList();
    // 城市下拉数据
    selectAreaByExpress().then((res) => {
      if (res.code == 200) {
        this.regionList = res.data;
      } else {
        this.$message.error("获取城市数据失败！");
      }
    });
  },
  methods: {
    //确认延期 按钮事件
    confirm() {
      let mainId = this.query.mainIdList
      let additionalId = this.query.sendIdList
      //确认延期
      this.selectList = this.postponeList
      this.query.dayNum = this.postponeForm.dayNum
      for (let i = 0; i < this.selectList.length; i++) {
        if (this.selectList[i].isCheck == true) {
          mainId.push(this.selectList[i].orderId)
        }
        if (this.selectList[i].sendorder != null) {
          if (this.selectList[i].sendorder.isCheck == true) {
            additionalId.push(this.selectList[i].sendorder.sid)
          }
        }

      }
      this.postponeDialog = false
      this.selectPostponeListDialog = false
      updatePostpone(this.query).then(res => {
        if (res.code == 200) {
          this.$message.success("延期成功");
        } else {
          this.$message.error("延期失败");
        }
        this.postponeDialog = false
        this.selectPostponeListDialog = false
      })
    },

    // select切换学员类型
    changeStudentType(val) {
      this.getAreaList(val);
    },
    //select切换学员类型 2
    changeStudentType1(val) {
      this.getAreaList1(val);
    },
    //切换城市?
    changeArea(val) {
      this.areaId = val;
      let oldCampus = null
      val.forEach(function (item) {
        oldCampus = item
      })
      this.transferForm.transferObjectId = oldCampus
      console.log(oldCampus);
    },
    //切换城市2
    changeAreas(val) {
      this.areaId = val;
      let newCampus = null
      val.forEach(function (item) {
        newCampus = item
      })
      this.transferForm.transferTargetId = newCampus
      console.log(newCampus);
    },
    //遍历tree性数据类型
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    //获取多级校区列表?
    getAreaList(typeId) {
      listCampusTree(typeId).then(val => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          this.treeList = val.data;
        }
      })
    },

    //获取多级校区列表?
    getAreaList1(typeId) {
      listCampusTree(typeId).then(val => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          this.treeLists = val.data;
        }
      })
    },

    //根据姓名 搜索教练?
    remoteMethod(name) {
      if (name !== "") {
        this.studentsLoading = true;
        transferSelectCoach(name).then((res) => {
          console.log(res);
          this.studentsLoading = false;
          if (res.code == 200) {
            this.studentsList = res.data.map((item) => {
              return {
                label: item.coachname,
                phone: item.coachphone,
                id: item.coachid
              }
            });
          } else {
            this.studentsList = [];
          }
        });
      } else {
        this.studentsList = [];
      }
    },
    // 根据区域选择的结果ID,获取卡型列表
    getAreaListId(areaListId) {
      this.postponeForm.areaList = areaListId;
      cardListByRegion(areaListId).then((res) => {
        if (res.code == 200) {
          this.cardList = res.data;
        } else {
          this.$message.info("获取卡种数据失败！");
        }
      });
    },
    //批量延期选择城市
    choiceRegion(regionId, index) {
      if (JSON.stringify(this.regionList[index])) {
        this.campusList = JSON.parse(
            JSON.stringify(this.regionList[index])
        ).campuses;
      }
      // 请求该城市卡种数据
      cardListByRegion(regionId).then((res) => {
        if (res.code == 200) {
          this.cardList = res.data;
        } else {
          this.$message.info("获取卡种数据失败！");
        }
      });
    },
    //学员转移选择城市
    transferChoiceRegion(regionId, type) {
      transferSelectCoach(regionId).then((val) => {
        console.log(val);
        if (val.code == 200) {
          if (type == "transferObject") {
            this.transferObjectCoachList = val.data;
          }
          if (type == "transferTarget") {
            this.transferTargetCoachList = val.data;
          }
        } else {
          this.$message.error("数据获取失败！");
        }
      });
    },


    //确定延期
    confirmPostpone(formName) {
      this.loadingDia = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.postponeTime != null) {
            this.postponeForm.startTime = this.dateFormat(this.postponeTime[0]);
            this.postponeForm.endTime = this.dateFormat(this.postponeTime[1]);
          } else {
            this.postponeForm.startTime = null;
            this.postponeForm.endTime = null;
          }
          selectPostpone(this.postponeForm).then((val) => {
            if (val.code == 200) {
              this.loadingDia = false
              this.postponeList = val.data;
              console.log(this.postponeList);
              this.postponeDialog = true;
            } else {
              this.$message.error("延期失败！");
            }
          });
        }
      });
    },

    //确定转移
    confirmTransfer(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          transferTeacher(this.transferForm).then((val) => {
            console.log(val);
            if (val.code === 200) {
              this.transferDialog = false
              this.transferForm.transferObjectId = null;
              this.transferForm.transferTargetId = null;
              this.$message.success("转移成功！");
            } else {
              this.$message.error("转移失败！");
            }
          });
        }
      });
    },
    //判断上传的文件
    exceedFile(files, fileList) {
      this.$message.warning(
          `只能选择 ${this.limitNum} 个文件，当前共选择了 ${
              files.length + fileList.length
          } 个`
      );
    },
    // 文件状态改变时的钩子
    fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xlsx") {
        this.fileList = [];
        this.$message.warning("只能上传后缀是.xlsx的文件");
      }
      if (size > 30) {
        this.fileList = [];
        this.$message.warning("文件大小不得超过30M");
      }
      if (extension === "xlsx" && size < 30) {
        this.sendEmailError = [];
        this.fileList.push(file.raw);
      }
    },
    //发送薪资条
    uploadFile() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传文件！");
      } else {
        this.hintMessage = "请稍等....";
        let form = new FormData();
        form.append("file", this.fileList[0]);
        sendEmail(form).then((val) => {
          this.hintMessage = "开始发送";
          this.sendEmailError = val.data;
          if (val.code == 200) {
            this.$message.success("发送成功");
            this.fileList = [];
          } else if (val.code == 403) {
            this.$message.warning(val.message);
          } else {
            this.$message.warning("发送失败,");
          }
        });
      }
    },
    //业绩核实
    performanceVerification() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传文件！");
        return;
      }
      this.emailHint = "请稍等....";
      let form = new FormData();
      form.append("file", this.fileList[0]);
      verification(form).then(value => {
        console.log(value);
        this.fileList = [];
        this.emailHint = "开始核实";
        let blob = new Blob([value], {
          type: "application/vnd.ms-excel",
        });
        console.log(value)
        let objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a"); //我们用模拟q标签点击事件
        const fname = "业绩核对结果表"; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute("download", fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click(); //点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      });
    },


    //导入免费课程
    uploadFreeLessonButton() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传文件！");
        return;
      }
      this.hintMessage = "请稍等....";
      let form = new FormData();
      form.append("file", this.fileList[0]);
      uploadFreeLesson(form).then((value) => {
        this.hintMessage = "开始导入";
        this.fileList = [];
        if (value.code == 200) {
          if (value.data.code == 5001) {
            ElNotification({
              title: "提示信息",
              dangerouslyUseHTMLString: true,
              message:
                  "<strong>" +
                  "<div>" +
                  "异常行数：" +
                  value.data.thisLine +
                  "</div>" +
                  "<div>" +
                  "异常原因：" +
                  value.data.message +
                  "</div>" +
                  '<div style="color: red;">请核实异常行数以上数据是否已经导入成功！</div>' +
                  "</strong>",
              duration: 0,
              type: "error",
            });
          } else {
            this.$message.success("导入成功");
            this.freeLesson = true
          }
        } else {
          this.$message.warning("导入失败,");
        }
      });
    },
    //导入订单
    uploadOrder() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传文件！");
        return;
      }
      this.hintMessage = "请稍等....";
      let form = new FormData();
      form.append("file", this.fileList[0]);
      uploadSkus(form).then((value) => {
        console.log(value);
        this.hintMessage = "开始导入";
        this.fileList = [];
        if (value.code == 200) {
          if (value.data.code == 5001) {
            ElNotification({
              title: "提示信息",
              dangerouslyUseHTMLString: true,
              message:
                  "<strong>" +
                  "<div>" +
                  "异常行数：" +
                  value.data.thisLine +
                  "</div>" +
                  "<div>" +
                  "异常原因：" +
                  value.data.message +
                  "</div>" +
                  '<div style="color: red;">请核实异常行数以上数据是否已经导入成功！</div>' +
                  "</strong>",
              duration: 0,
              type: "error",
            });
          } else {
            this.$message.success("导入成功");
          }
        } else {
          this.$message.warning("导入失败,");
        }
      });

    },
    //退费通知
    returnInform() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传文件！");
        return;
      }
      this.hintMessage = "请稍等....";
      let form = new FormData();
      form.append("file", this.fileList[0]);
      financeSend(form).then((value) => {
        this.$message.success("导入成功");
        this.hintMessage = "开始通知";
        this.fileList = [];
        let blob = new Blob([value], {
          type: "application/vnd.ms-excel",
        });
        let objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a"); //我们用模拟q标签点击事件
        const fname = "通知结果反馈表"; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute("download", fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click(); //点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      });
    },

    //批量挪课时执行
    batchMoveCourse() {
      this.hintMessage = "请稍等....";
      console.log("挪课时执行,开始1111!!!!")
      if (this.moveCourseTime != null) {
        this.moveCourseFrom.moveStartTime = this.dateFormat(this.moveCourseTime[0]);
        this.moveCourseFrom.moveEndTime = this.dateFormat(this.moveCourseTime[1]);
      } else {
        this.$message.error("挪课时的时间区间必填!!!");
        this.hintMessage = "开始课时挪动";
        return;
      }

      console.log("挪课时执行,开始222!!!!")
      //执行批量课时挪动
      postBatchMoveCourse(this.moveCourseFrom).then(
          (val) => {
            if (val.code === 200) {
              this.$message.success(val.data);
              this.hintMessage = "课时挪动完成,请进入订单课时中核实";
            } else {
              this.$message.error(val.data);
              this.hintMessage = "开始课时挪动";
            }
          }
      );
    },

    //批量 将某些订单ID 转卡到指定目标校区
    partOrderMoveArea() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传列为orderId、targetAreaId的Excel文件！");
      } else {
        this.hintMessage = "请稍等....";
        let form = new FormData();
        form.append("file", this.fileList[0]);
        partOrderMoveArea(form).then((val) => {
          this.hintMessage = "开始导入";
          this.partOrderMoveAreaError = val.data;
          if (val.code === 200) {
            this.$message.success("执行成功,请核对");
            this.fileList = [];
          } else {
            this.$message.warning("执行失败," + val.message);
          }
        });
      }
    },
    //固化 月初订单分摊金额
    importOrderBalance() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传列为分摊大表原表的Excel文件！");
      } else {
        this.hintMessage = "请稍等....";
        let form = new FormData();
        form.append("file", this.fileList[0]);
        importOrderBalance(form).then((val) => {
          this.hintMessage = "开始导入";
          this.importOrderBalanceError = val.data;
          if (val.code === 200) {
            this.$message.success("执行成功,请核对");
            this.fileList = [];
          } else {
            this.$message.warning("执行失败," + val.message);
          }
        });
      }
    },

    //打开工具栏弹出层
    openDialog(val) {
      if (val == "transferDialog") {
        this.transferDialog = true;
      }
      if (val == "selectPostponeListDialog") {
        this.selectPostponeListDialog = true;
      }
      if (val == "emailDialog") {
        this.emailDialog = true;
        this.hintMessage = "开始发送";
      }
      if (val == "performanceDialog") {
        this.performanceDialog = true;
        this.hintMessage = "开始核实";
      }
      if (val == "orderDialog") {
        this.orderDialog = true;
        this.hintMessage = "开始导入";
      }
      if (val == "returnDialog") {
        this.returnDialog = true;
        this.hintMessage = "开始通知";
      }
      if (val == "batchMoveCourseDialog") {
        this.batchMoveCourseDialog = true;
        this.hintMessage = "开始课时挪动";
      }
      if (val == "partOrderMoveAreaDialog") {
        this.partOrderMoveAreaDialog = true;
        this.hintMessage = "开始导入";
      }
      if (val == "importOrderBalanceDialog") {
        this.importOrderBalanceDialog = true;
        this.hintMessage = "开始导入";
      }
      if (val == 'ordersExcelToolsDialog') {
        this.ordersExcelToolsDialog = true;
      }
      if (val == 'freeLessonDialog') {
        this.hintMessage = "开始导入";
        this.freeLesson = true
      }
      if (val == "exportPhoneDialog") {
        this.$refs.child.dialogSwitch();
      }
      //推送工具
      if (val == "pushOrderDialog") {
        this.$refs.total.pushOrder();
      }
      if (val == "pushCourseDialog") {
        this.$refs.total.pushCourse();
      }
      if (val == "pushAdvanceDialog") {
        this.$refs.total.pushAdvance();
      }
      if (val == "pushApportionDialog") {
        this.$refs.total.pushApportion();
      }
      //补充工具
      if (val == "pushEquipDialog") {
        this.$refs.total.pushEquip();
      }
      if (val == "pushZhaoShangCRMOrderDialog") {
        this.$refs.total.pushZhaoShangCRMOrder();
      }

      if (val == "performanceTarget") {
        this.$refs.performanceTarget.openDialog();
      }
    },
    //日期格式化
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
      return time;
    },
    // 导出数据
    orderExcel() {
      this.Time = this.dateFormat(this.Time)
      console.log(this.Time);
      toolOrdersExcel(this.Time)
    },
  },
  watch: {
    checkAllVice(newVal) {
      this.postponeList.forEach(function (item) {
        if (newVal == true && item.sendorder != null && item.sendorder.sid != null) {
          item.sendorder.isCheck = true;
        } else if (item.sendorder != null) {
          item.sendorder.isCheck = false
        }
      })
    },
    checkAllMain(newVal) {
      let yes = 0;
      this.postponeList.forEach(function (item) {
        if (newVal == true && item.orderId != null) {
          yes += 1;
          item.isCheck = true;
        } else {
          item.isCheck = false;
        }
      })
      if (newVal == true) {
        this.$message.success("已选" + yes + '个主卡！');
      }
    },
  }

};
</script>

<style scoped>
.postponeList {
  overflow-y: scroll;
  height: 500px
}

.postponeList::after {
  content: "";
  width: 300px;
  /* 不可以提供高度 */
  /* height: 200px; */
}

.tool {
  display: flex;
  /*让一行中的元素平均分配宽度*/
  justify-content: space-around;
  align-items: center;
  /*元素在一行放不下时，自动换行*/
  flex-wrap: wrap;
  margin: 10px;
}

.tool:after {
  content: "";
  width: 30%;
}

.tool .tool-item {
  text-align: center;
  /*鼠标放上去变成小手*/
  cursor: pointer;
  margin: 12px;
}

.tool .tool-item .img {
  /*图片在盒子里垂直水平居中*/
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 14px;
  /*盒子阴影*/
  box-shadow: 8px 8px 30px rgba(56, 54, 54, 0.2),
  -18px -18px 30px rgba(255, 255, 255, 1);
  /*过渡时间，先加速后减速*/
  transition: all 0.2s ease-out;
  margin-bottom: 10px;
}

.tool .tool-item .tool-title {
  color: #96a6b8;
  font-size: 12px;
  margin-top: 10px;
}

.tool .tool-item .img:hover {
  /*inset 是内部阴影，默认为外部阴影outset*/
  box-shadow: 0 0 0 rgba(113, 111, 111, 0.2), 0 0 0 rgba(255, 255, 255, 0.8),
  inset 18px 18px 30px rgba(125, 124, 124, 0.1),
  inset -18px -18px 30px rgba(255, 255, 255, 1);
}

.card {
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  border-radius: 20px;
}

.searchType {
  position: absolute;
  left: 100px;
  top: 17px;
}

.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  padding: 40px 0 30px 0;
}

.item {
  width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .Menuitem:last-child{
  margin: 0 0 0 -35px;
} */

.list-empty {
  width: 30%;
  height: 0px;
}
</style>
