import request from '../utils/request';
export const cardListByRegion = (list) => {
    return request({
        url: '/sys/card/cardListByRegion',
        method: 'post',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
}
