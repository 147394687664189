<template>
  <div>
    <el-dialog :title="'续签合同'" v-model="contractDialog" width="1000px">
    <el-form style="height: 200px">
      <el-row style="display:flex;justify-content: space-around;">
        <el-col :span="8">
          <el-form-item prop="signContractFirstParty" label="合同签署主体" label-width="110px">
            <el-select
                size="large"
                style="width:220px"
                v-model="contract.signContractFirstParty"
            >
              <el-option v-for="item in CompanyList" :value="item.dictValue*1" :key="item.dictValue" :label="item.dictLabel"></el-option>
            </el-select>

          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="coachRoster.contractType" label="合同类型"  label-width="110px">
            <el-select v-model="contract.contractType" style="width:220px">
              <el-option v-for="(item,i) in contractTypeList" :key="i" :label="item.dictLabel" :value="item.dictValue*1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="display:flex;justify-content: space-around;">
        <el-col :span="8">
          <el-form-item prop="contractStartTime" label="合同开始日期" label-width="110px">
            <el-date-picker
                v-model="contract.contractStartTime"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                placeholder="请选择日期"
                size="large"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="contractEndTime" label="合同结束日期" label-width="110px">
            <el-date-picker
                v-model="contract.contractEndTime"
                type="date"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                placeholder="请选择日期"
                size="large"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
      <template #footer>
        <el-button  @click="contractDialog = false">取消</el-button>
        <el-button type="primary"  @click="confirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {selectDictByType} from '@/api/dict'
import {updateCoachContract} from "@/api/coachRoster";
export default {
  name: "ContractExtension",
  props: {
    item: String,
  },
  data(){
    return{
      contractDialog:false,
      CompanyList:[],
      contract:{
        contractEndTime:'',
        contractStartTime:'',
        contractType:'',
        signContractFirstParty:'',
        coachId:null,
        workId:null
      },
    }
  },
  methods:{
    openDialog(){
      this.contractDialog = true;
    },
    confirm(){
      this.contract.coachId = this.item.coachId;
      this.contract.workId = this.item.workNumber;
      this.contract.contractStartTime = this.dateFormat(this.contract.contractStartTime)
      this.contract.contractEndTime = this.dateFormat(this.contract.contractEndTime)
      updateCoachContract(this.contract).then(res=>{
        this.contractDialog = false
        if(res.code == 200){
          this.$emit('refreshList');
          this.$message.success('修改成功')
          this.editContract()

        }else{
          this.$message.error(res.message)
        }
      })
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + m + "-" + d;
      return time;
    },
  },
  created(){
    selectDictByType('signContractFirstParty').then(res=>{
      this.CompanyList = res.data
    })
    selectDictByType('contractType').then(res=>{
      this.contractTypeList = res.data
    })
  }
}
</script>

<style scoped>

</style>