<template>
  <div style="width: 100%;">
    <div class="tags" @contextmenu="rightClick"  v-if="showTags">
      <ul style="padding-left: 20px">
        <li
            class="tags-li"
            v-for="(item,index) in tagsList"
            :class="{'active': isActive(item.path)}"
            :key="index"
        >
          <router-link :to="item.path" class="tags-li-title">
            {{item.title}} <el-divider direction="vertical" ></el-divider>
          </router-link>
<!--          <span class="tags-li-icon" @click="closeTags(index)">-->
<!--                    <i class="el-icon-close"></i>-->
<!--                </span>-->
        </li>
      </ul>
    </div>
  </div>
  <div
       v-show="menuVisible"
       class="menu">
    <div class="close">
      <span style="cursor: pointer" @click="closeOther">关闭其他</span>

    </div>
    <div class="close">
      <span style="cursor: pointer" @click="closeAll">关闭所有</span>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      menuVisible:false
    }
  },
    computed: {
        tagsList() {
            return this.$store.state.tagsList;
        },
        showTags() {
            return this.tagsList.length > 0;
        }
    },
    methods: {

      rightClick(row) {
        this.testModeCode = row.testModeCode
        this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
        this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
        this.CurrentRow = row
        var menu = document.querySelector('.menu')
        this.styleMenu(menu)
        event.preventDefault() //关闭浏览器右键默认事件
      },
      foo() {
        // 取消鼠标监听事件 菜单栏
        this.menuVisible = false
        document.removeEventListener('click', this.foo) // 关掉监听，
      },
      styleMenu(menu) {
        if (event.clientX > 1800) {
          menu.style.left = event.clientX +1000 + 'px'
        } else {
          menu.style.left = event.clientX -300 + 'px'
        }
        document.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
        if (event.clientY > 700) {
          menu.style.top = event.clientY - 3 + 'px'
        } else {
          menu.style.top = event.clientY - 50 + 'px'
        }
      },

        isActive(path) {
            return path === this.$route.fullPath;
        },
        // 关闭单个标签
        closeTags(index) {
          console.log(444);
            const delItem = this.tagsList[index];
            this.$store.commit("delTagsItem", { index });
            const item = this.tagsList[index]
                ? this.tagsList[index]
                : this.tagsList[index - 1];
            if (item) {
                delItem.path === this.$route.fullPath &&
                    this.$router.push(item.path);
            } else {
                this.$router.push("/");
            }
        },
        // 关闭全部标签
        closeAll() {
            this.$store.commit("clearTags");
            this.$router.push("/");
        },
        // 关闭其他标签
        closeOther() {
            const curItem = this.tagsList.filter(item => {
                return item.path === this.$route.fullPath;
            });
            this.$store.commit("closeTagsOther", curItem);
        },
        // 设置标签
        setTags(route) {
            const isExist = this.tagsList.some(item => {
                return item.path === route.fullPath;
            });
            if (!isExist) {
                if (this.tagsList.length >= 8) {
                    this.$store.commit("delTagsItem", { index: 0 });
                }
                this.$store.commit("setTagsItem", {
                    name: route.name,
                    title: route.meta.title,
                    path: route.fullPath
                });
            }
        },
        handleTags(command) {
            command === "other" ? this.closeOther() : this.closeAll();
        }
    },
    watch: {
        $route(newValue) {
            this.setTags(newValue);
        }
    },
    created() {
        this.setTags(this.$route);
        // 关闭当前页面的标签页
        // this.$store.commit("closeCurrentTag", {
        //     $router: this.$router,
        //     $route: this.$route
        // });
    }
};
</script>

<style scoped>
.tags {
    position: relative;
    height: 30px;
    overflow: hidden;
    background: #fff;
    padding-right: 120px;
}
.close{
  display: flex;
  justify-content: center;
  padding: 8px;

}
.menu {
  padding: 12px 0;
  position: absolute;
  background-color: #fff;
  width: 90px;
  font-size: 13px;
  color: #444040;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 1000;
}
.tags ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.tags-li {
    float: left;
    font-size: 12px;
    overflow: hidden;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {


}

.tags-li.active {

    color: #409eff;
    background-color: #ffffff;
    border: none;
}

.tags-li-title {
    float: left;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #909399;
    margin-right: 5px;
}

.tags-li.active .tags-li-title {
  color: #409eff;
}

.tags-close-box {
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    padding-top: 1px;
    text-align: center;
    width: 110px;
    height: 30px;
    background: #fff;
    box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
    z-index: 10;
}
</style>
