<template>
    <el-select :size="size" placeholder="区域类型" style="width: 120px;margin-right: 10px" @change="changeStudentType" v-model="studentType">
      <div v-if="sysStudentType==3">
        <el-option label="全部" :value="null"></el-option>
        <el-option label="中招体育" value="1"></el-option>
        <el-option label="少年" value="2"></el-option>
        <el-option label="高考体育" value="4"></el-option>
      </div>
      <div v-if="sysStudentType!=3">
        <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
        <el-option v-if="sysStudentType==1" label="高考体育" value="4"></el-option>
        <el-option v-else label="少年" value="2"></el-option>
      </div>
    </el-select>
    <el-cascader
        ref="cascader"
        :size="size"
        style="margin-right: 10px;width: 200px;"
        placeholder="请选择区域"
        :options="treeList"
        collapse-tags
        :collapse-tags-tooltip="true"
        clearable
        @change="changeArea"
        :props="{ multiple: multiple,value: 'id',label: 'name',children: 'children'}"/>
</template>

<script>
import {listAreaTreeAddCoach} from "@/api/area.js";
export default {
  name: "SelectArea",
  props:{
    size: String,
    clear:String,
    multiple: {
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      treeList:[],
      areaId:[],
      sysStudentType:localStorage.getItem('studentType'),
      studentType:null
    }
  },
  methods: {
    changeStudentType(val){
      this.$emit('Flag',val)
      // console.log(val)
      this.getAreaList(val);
    },
    changeArea(val){
      this.areaId = val;
      let areaListId = [];
      if (this.multiple ==true){
        val.forEach(function(item){
          areaListId.push(...item)
        })
        this.$emit('areaListId',areaListId)
      }else {
        this.$emit('areaListId',val== null ? null : val[val.length-1])
      }

    },
    getTypeList(commodityType) {
      if (commodityType!=null){
        commodityType.forEach(items => {
          if (items!=null){
            if (items.children!=null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    getAreaList(typeId){
      listAreaTreeAddCoach(typeId).then(val => {
        if (val.code==200){
          this.getTypeList(val.data);
          this.treeList = val.data;
        }
      })
    }
  },
  created() {
    this.getAreaList();
  },
  watch:{ 
      clear:{ 
        // immediate: true,    
      handler:function(oldVal,newVal){ 
        if(oldVal!=newVal){ 
          this.$refs.cascader.$refs.panel.clearCheckedNodes();  
          this.studentType = null
        }
      },
      deep: true, // 深度监听
    
    },  
  }

}
</script>

<style scoped>

</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>