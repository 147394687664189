<template>
  <el-dialog
      v-model="exportPhoneDialog"
      width="900px"
      title="导出电话"
      :append-to-body="true"
      :draggable="true"
  >
       <div style="display:flex;justify-content: center">
        <el-upload
            drag
            :limit="limitNum"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="margin: 10px 0;display:flex;justify-content: center">
        <div style="display: flex;justify-content: space-between;width: 360px">
          <el-input style="width: 67%;font-size: 12px" v-model="note" maxlength="15" show-word-limit placeholder="请输入本次导出原因！" />
          <el-input style="width: 30%;font-size: 12px" v-model="code" placeholder="请输入验证码" />
        </div>
      </div>

    <div style="display: flex; justify-content: center; padding-top: 30px">
      <el-button-group>
        <el-button v-if="!disAble" size="small" type="primary" style="margin-left:10px" @click="getSecurity">获取验证码</el-button>
        <el-button v-else type="info" size="small" style="margin-left:10px;width: 70px;height: 16px;" disabled>{{ daojishi }}</el-button>
        <el-button type="primary" size="small" @click='confirUpload'>导出手机号</el-button>
      </el-button-group>
    </div>
    <div>
      <div>
        1.选择上传的文件，
      </div>
      <div>
        2.输入本次导出原因，
      </div>
      <div>
        3.点击获取验证码后，系统将向“张腾辉：18637430919”发送一条验证码，需您向他进行索取验证码。
      </div>

      <div>
        注意事项：第一步上传文件和导出电话的文件需保持一致，不一致也将无法进行导出。
      </div>

    </div>
  </el-dialog>
</template>

<script>
import * as XLSX from 'xlsx'
import {
  uploadExcell,
  getCode
} from '@/api/orders'

export default {
  name: "ExportPhone",
  data() {
    return {  
      code:null,
      exportPhoneDialog: false,
      fileList:[],
      //薪资条发放
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      excellFile:null,
        note:'',
        number:0,
      disAble:false,
      daojishi:null
    }
  },
  methods:{
    confirUpload(){ 
      let data = new FormData()
      data.append('file',this.excellFile)
      data.append('code',this.code)
      if(!this.code){ 
        this.$message.error('请填写验证码！')
        return
      }
      uploadExcell(data).then(res=>{ 
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        let objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a"); //我们用模拟q标签点击事件
        const fname = "导出手机号"; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute("download", fname);
        document.body.appendChild(link);
        link.click(); //点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
        if(res.code==200){
          this.$message.success('导入成功!')
          this.exportPhoneDialog = false
        }else{
          console.log('AAA');
          // this.$message.error(res.message)
        }
      })
    },
    //获取验证码
    getSecurity(){ 
      if(!this.note){ 
        this.$message.error('请填写备注消息')
        return
      }
      if(!this.number){
       this.$message.error('请上传有效文件')
        return
      }
      getCode(this.note,this.number).then(res=>{ 
       if(res.code==200){ 
        this.disAble = true
        this.Time()
       }else{ 
        this.$message.error(res.message);
       }
      })
    },
    Time(){ 
      this.daojishi = 60
      let timer = setInterval(()=>{ 
        if(this.daojishi==1){ 
          clearInterval(timer)
          this.disAble = false
        }else{ 
          this.daojishi--
        }
      },1000)
    },
    dialogSwitch(){
      this.exportPhoneDialog = true;
    },
    //判断上传的文件
    exceedFile(files, fileList) {
      this.$message.warning(
          `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`
      );
    },
    // 文件状态改变时的钩子
    async fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xlsx") {
        this.fileList = [];
        this.$message.warning("只能上传后缀是.xlsx的文件");
      }
      if (size > 10) {
        this.fileList = [];
        this.$message.warning("文件大小不得超过10M");
      }
      if (extension == "xlsx" && size < 10) {
        this.sendEmailError = [];
        this.fileList.push(file.raw);
      }
      this.excellFile =  file.raw
      let data = await this.readFile(file.raw);
      const workbook = XLSX.read(data, {
        // 以字符编码方式解析
        type: 'binary'
      })
      // 获取第一张表
      const exlname = workbook.SheetNames[0]
      const exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]) // 生成json表格内容
      console.log(exl);
      this.number = exl.length
      console.log(this.number);
      // 将 JSON 数据挂到 data 里
      let arr = []
      exl.forEach(item => {
        arr.push(item.name)
      })
    },

    readFile(file) {
      //文件读取
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file); //以二进制的方式读取
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
  },
}
</script>

<style scoped>

</style>