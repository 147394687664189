<template>
  <el-dialog
      v-model="performanceTargetDialog"
      width="900px"
      title="导入业绩目标"
      :append-to-body="true"
      :draggable="true"
  >
    <div style="display:flex;justify-content: center">
      <el-upload
          drag
          :limit="limitNum"
          :auto-upload="false"
          :on-change="fileChange"
          :on-exceed="exceedFile"
          :file-list="fileList"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <div style="display: flex; justify-content: center; padding: 50px 0 80px 0">
      <el-button type="primary" size="small" @click='confirUpload'>开始导入</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as XLSX from "xlsx";
import {
  uploadPerformanceTarget,
} from '@/api/performanceTarget'
export default {
  name: "PerformanceTarget",
  data() {
    return {
      code:null,
      performanceTargetDialog: false,
      fileList:[],
      //薪资条发放
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      excellFile:null,
      note:'',
      number:0,
      disAble:false,
      daojishi:null
    }
  },
  methods:{
    openDialog(){
      this.performanceTargetDialog = true;
    },
    confirUpload(){
      let data = new FormData()
      data.append('file',this.excellFile)
      uploadPerformanceTarget(data).then(res=>{
        // let blob = new Blob([res], {
        //   type: "application/vnd.ms-excel",
        // });
        // let objectUrl = URL.createObjectURL(blob);
        // const link = document.createElement("a"); //我们用模拟q标签点击事件
        // const fname = "导出手机号"; //下载文件的名字
        // link.href = objectUrl;
        // link.setAttribute("download", fname);
        // document.body.appendChild(link);
        // link.click(); //点击
        // document.body.removeChild(link); // 下载完成移除元素
        // window.URL.revokeObjectURL(URL); // 释放掉blob对象
        if(res.code==200){
          this.$message.success('导入成功!')
          this.exportPhoneDialog = false
        }else {
          this.$message.error('导入失败!')
        }
      })
    },

    dialogSwitch(){
      this.exportPhoneDialog = true;
    },
    //判断上传的文件
    exceedFile(files, fileList) {
      this.$message.warning(
          `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`
      );
    },
    // 文件状态改变时的钩子
    async fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xlsx") {
        this.fileList = [];
        this.$message.warning("只能上传后缀是.xlsx的文件");
      }
      if (size > 10) {
        this.fileList = [];
        this.$message.warning("文件大小不得超过10M");
      }
      if (extension == "xlsx" && size < 10) {
        this.sendEmailError = [];
        this.fileList.push(file.raw);
      }
      this.excellFile =  file.raw
      let data = await this.readFile(file.raw);
      const workbook = XLSX.read(data, {
        // 以字符编码方式解析
        type: 'binary'
      })
      // 获取第一张表
      const exlname = workbook.SheetNames[0]
      const exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]) // 生成json表格内容
      this.number = exl.length
      // 将 JSON 数据挂到 data 里
      let arr = []
      exl.forEach(item => {
        arr.push(item.name)
      })
    },

    readFile(file) {
      //文件读取
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file); //以二进制的方式读取
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
  },
}
</script>

<style scoped>

</style>