import request from '../utils/request';


/**
 * 获取教练列表
 */
export const selectRosterList = (query) => {
    return request({
        url: '/sys/coachRoster/selectRosterList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 更新花名册信息
 */
export const updateRoster = (coach) => {
    return request({
        url: '/sys/coachRoster/updateRoster',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(coach),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 转正办理
 */
export const official = (official) => {
    return request({
        url: '/sys/coachRoster/official',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(official),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 离职办理
 */
export const dimission = (dimission) => {
    return request({
        url: '/sys/coachRoster/dimission',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(dimission),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 更新教练的合同
 */
export function updateCoachContract(query) {
    return request({
        url: '/sys/coachRoster/updateCoachContract',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

/**
 * 查询教练合同到期列表
 */
export const contractExpireCoachList = () => {
    return request({
        url: '/sys/coach/contractExpireCoachList',
        method: 'get',
    });
};