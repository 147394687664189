<template>
    <div>
      <!-- 根据实际情况修改 -->
      <div v-for="item in list" :key="item.index">
        <el-menu-item background-color="#324157" :hidden="item.isButton == 1" v-if="item.children == null || item.children.length === 0" :index="item.index">
          <div style="display: flex">
            <div style="width: 32px;"><i :class="item.icon"></i></div>
            <span >{{ item.title }}</span>
          </div>
        </el-menu-item>
        <el-sub-menu background-color="#324157" v-if="item.children != null &&item.children.length > 0" :index="item.index">
          <template #title>
            <div style="display: flex">
              <div style="width: 32px;"><i :class="item.icon"></i></div>
              <span :style="collapse?'display:none':''">{{ item.title }}</span>
            </div>
          </template>
          <ActMenu :list="item.children"></ActMenu>
        </el-sub-menu>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "ActMenu",
    props: {
      list: Array
    }, 
    computed: {
      collapse() {
        return this.$store.state.collapse
      },
    },
  };
  </script>
<style scoped>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 250px;
  }
</style>