import request from '../utils/request';
export const login= (data) => {
    return request({
        url: '/sys/login',
        method: 'post',
        data
    });
};

/**
 * 获取验证码
 * @param phone
 * @returns {AxiosPromise}
 */
export function getAuthCode(phone){
    return request({
        url: '/sys/getAuthCode',
        method: 'get',
        params: {phone}
    });
}
/**
 * 根据验证码登陆
 * @param form
 * @returns {AxiosPromise}
 */
export function loginByAuthCode(form) {
    return request({
        url: '/sys/webLogin',
        method: 'post',
        data: form
    })
}
/**
 * 修改密码
 * @param form
 * @returns {AxiosPromise}
 */
export function rePwd(form) {
    return request({
        url: '/sys/rePwd',
        method: 'post',
        data: form
    })
}
