export default (app) => {
    app.directive('loadmore', {
        // 自定义指令的声明周期函数
        updated(el, bind) {
            console.log(el, bind)
        }
        ,
        beforeMount(el, binding) {
            // 获取element，定义scroll
            let select_dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
            select_dom.addEventListener('scroll', function () {
                let height = this.scrollHeight - this.scrollTop <= this.clientHeight
                if (height) {
                    binding.value()
                }
            })
        }
    })
}
