<template>
 <keep-alive>
    <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
  </keep-alive>
<router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>

</template>
<script>
import { sendWebSocket, heartCheck } from "@/utils/websocket.js";
import bus from '@/utils/bus.js'

export default {
  components:{
  },
  data(){ 
    return{
      detailItem:null,
      timer:null,
    }
  },
  created: function () {
    if(this.wsMessage){
       sendWebSocket(this.wsMessage);
    }
  },
  
  methods: {

    //webSocket 接收后端消息
    wsMessage(val) {
      // console.log(val);
      if (val.api == "downExcel") {
        bus.emit('sendMsg',val.type)
        this.$store.commit("setMessage", val);   
        this.$store.commit("setMessageReadExcell", false);
      }
      else{ 
        this.$store.commit("setWebSocket", val.api);
        this.$store.commit("setMessageRead", false);
        this.$store.commit("setMessage", val);
      }
      

      clearInterval(this.timer); // 清除定时器
      this.timer = null;
      this.timers(); //重新开始心跳链接倒计时
    },
    // 定时器
    timers() {
      this.timer = setInterval(() => {
        setTimeout(heartCheck(), 0);
      }, 1000 * 60 * 16);
    },
  },
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/font/font_rnuwc8op6k/iconfont.css";
@import "./assets/font/font_cg5bvxkdf7v/iconfont.css";
@import "./assets/css/color-dark.css";
.el-table .cell {
  text-align: center !important;
  text-overflow:initial !important
}
::-webkit-scrollbar {
width: 8px;
height: 8px;
background-color: white;
border-radius: 6px;
}

::-webkit-scrollbar-thumb {
background-color: #cdcdcd;
border-radius: 6px;
}

</style>
