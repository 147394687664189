import request from '../utils/request';

/**
 * 获取根据类型字典数据
 * @param query
 * @returns {AxiosPromise}
 */
export const selectDictByType = (key) => {
    return request({
        url: 'web/dict/selectDictByType',
        method: 'GET',
        params:{key}
    });
};

export const selectDictByTypeAndValue = (type,value) => {
    return request({
        url: 'web/dict/selectDictByTypeAndValue',
        method: 'get',
        params: {type,value},
    });
};